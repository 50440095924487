import React, { useState } from 'react';
import { InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const PasswordInput = ({ password, setPassword, placeholder, size }) => {
	const [showPassword, setShowPassword] = useState(false);

	function togglePassword() {
		setShowPassword(!showPassword);
	}

	return (
		<InputGroup size={size}>
			<Input
				pr="4.5rem"
				borderRadius="15px"
				type={showPassword ? 'text' : 'password'}
				placeholder={placeholder}
				value={password}
				onChange={setPassword}
			/>
			<InputRightElement width="4.5rem" marginRight="30px" onClick={togglePassword}>
				{showPassword ? <ViewOffIcon color="#7F7F7F" /> : <ViewIcon color="#7F7F7F" />}
			</InputRightElement>
		</InputGroup>
	);
};

PasswordInput.propTypes = {
	password: PropTypes.string.isRequired,
	setPassword: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	size: PropTypes.string,
};

PasswordInput.defaultProps = {
	placeholder: 'Mot de passe (obligatoire)',
	size: 'lg',
};

export default PasswordInput;
