import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
import Home from './screens/Home';

function App() {
	return (
		<ChakraProvider>
			<Router>
				<Switch>
					<Route path="*" component={Home} />
				</Switch>
			</Router>
		</ChakraProvider>
	);
}

export default App;
