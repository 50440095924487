import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, Text, useDisclosure, Box, Center, Button, VStack } from '@chakra-ui/react';

import PasswordInput from 'components/PasswordInput';
import { recoverPassword as tryPasswordRecover } from 'services/auth';

let token;

const usePasswordRecover = () => {
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [errorMessage, setError] = useState(null);

	useEffect(() => {
		if (errorMessage === null) {
			if ((passwordConfirm || password) && passwordConfirm !== password) {
				setError('Les mots de passe ne correspondent pas.');
			}
		} else if (passwordConfirm === password) {
			setError(null);
		}
	}, [password, passwordConfirm]);

	return {
		password,
		setPassword,
		passwordConfirm,
		setPasswordConfirm,
		errorMessage,
		setError,
	};
};

const useModalResetPassword = () => {
	const { isOpen, onOpen } = useDisclosure();
	const redirectGomojo = () => {
		window.location.replace(process.env.REACT_APP_LANDING_URL);
	};
	return {
		trigger: (recoverToken) => {
			token = recoverToken;
			onOpen();
		},
		close: redirectGomojo,
		modal: () => {
			const { password, setPassword, passwordConfirm, setPasswordConfirm, errorMessage, setError } =
				usePasswordRecover();

			const recoverPassword = async () => {
				try {
					if (password.length < 8) setError('8 caractères minimum pour le mot de passe');
					else {
						await tryPasswordRecover({ password, confirmPassword: passwordConfirm }, token);
						redirectGomojo();
					}
				} catch (e) {
					if (e.response && Object.prototype.hasOwnProperty.call(e.response.data, 'message')) {
						const message = e.response.data.message.replace('password: ', '');
						setError(message);
					} else if (e.response && Object.prototype.hasOwnProperty.call(e.response.data, 'error')) {
						setError(e.response.data.error.password);
					} else setError(e.message);
				}
			};

			return (
				<Modal isCentered closeOnOverlayClick={false} closeOnEsc={false} isOpen={isOpen}>
					<ModalOverlay backgroundColor="rgba(13,71,161, 0.75)" zIndex={1400} />
					<ModalContent borderRadius="30px" my={0} py="2rem" px="2rem">
						<Box d="flex" flexDir="column" alignItems="center" justifyContent="center">
							<Text fontFamily="HVD" fontSize="1.5rem" textAlign="center" color="#0C50AB" pb="1rem">
								Mise à jour du mot de passe
							</Text>
							<VStack width="100%" my="1rem" spacing="1rem">
								<PasswordInput
									placeholder="Nouveau mot de passe"
									password={password}
									setPassword={(e) => {
										setPassword(e.target.value);
									}}
								/>
								<PasswordInput
									placeholder="Confirmation nouveau mot de passe"
									password={passwordConfirm}
									setPassword={(e) => {
										setPasswordConfirm(e.target.value);
									}}
								/>
								<Text textAlign="center" font="Work Sans Medium" color="#E6265C">
									{errorMessage}
								</Text>
							</VStack>
							<Center>
								<Button
									minWidth="50%"
									borderRadius="18px"
									fontFamily="HVD"
									fontSize="0.9rem"
									fontWeight="400"
									color="#fff"
									disabled={!passwordConfirm || !password || !!errorMessage}
									onClick={recoverPassword}
									textTransform="uppercase"
									backgroundImage="linear-gradient(135deg, #0093ff 0%, #00deff 100%)"
									padding="12px 16px"
									opacity="1"
									_hover={{ cursor: 'pointer' }}
								>
									Mettre a jour
								</Button>
							</Center>
						</Box>
					</ModalContent>
				</Modal>
			);
		},
	};
};

export default useModalResetPassword;
