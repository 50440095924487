import React, { useState } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Text,
	useDisclosure,
	Box,
	Image,
	Center,
	Button,
	Spinner,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { verifyAccount } from 'services/auth';

const ErrorContent = ({ onClose, message }) => (
	<Box d="flex" flexDir="column" alignItems="center" justifyContent="center">
		<Text fontFamily="HVD" textTransform="uppercase" fontSize="1.5rem" textAlign="center" color="#0C50AB" pb="1rem">
			Une Erreur est survenue :(
		</Text>
		<Text fontFamily="WSMedium" fontSize="1rem" textAlign="center" color="rgba(0, 0, 0, 0.5)">
			{message}
		</Text>
		<Center>
			<Button
				borderRadius="18px"
				fontFamily="HVD"
				fontSize="0.9rem"
				fontWeight="400"
				color="#fff"
				onClick={onClose}
				textTransform="uppercase"
				backgroundImage="linear-gradient(135deg, #0093ff 0%, #00deff 100%)"
				margin="1rem"
				padding="12px 16px"
				opacity="1"
				_hover={{ cursor: 'pointer' }}
			>
				Go
			</Button>
		</Center>
	</Box>
);

ErrorContent.propTypes = {
	onClose: PropTypes.func.isRequired,
	message: PropTypes.string,
};

ErrorContent.defaultProps = {
	message: '',
};

const SuccessContent = ({ onClose }) => (
	<Box d="flex" flexDir="column" alignItems="center" justifyContent="center">
		<Image
			src="https://res.cloudinary.com/dbyw8lbig/image/upload/f_auto,fl_lossy,q_auto/v1608819125/Profil/mail_sent_s1mwzb.png"
			size="30vh"
			maxH="135px"
			maxW="200px"
			pb="1.5rem"
		/>
		<Text fontFamily="HVD" textTransform="uppercase" fontSize="1.5rem" textAlign="center" color="#0C50AB" pb="1rem">
			Email validé
		</Text>
		<Text fontFamily="WSMedium" fontSize="1rem" textAlign="center" color="rgba(0, 0, 0, 0.5)">
			Bravo, votre compte GOMOJO est confirmé.
		</Text>
		<Center>
			<Button
				borderRadius="18px"
				fontFamily="HVD"
				fontSize="0.9rem"
				fontWeight="400"
				color="#fff"
				onClick={onClose}
				textTransform="uppercase"
				backgroundImage="linear-gradient(135deg, #0093ff 0%, #00deff 100%)"
				margin="1rem"
				padding="12px 16px"
				opacity="1"
				_hover={{ cursor: 'pointer' }}
			>
				Go
			</Button>
		</Center>
	</Box>
);

SuccessContent.propTypes = {
	onClose: PropTypes.func.isRequired,
};

const useVerifyModal = () => {
	const { isOpen, onOpen } = useDisclosure();
	const [isVerified, setIsVerified] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const redirectGomojo = () => {
		window.location.replace(process.env.REACT_APP_LANDING_URL);
	};
	const sendVerify = async (token) => {
		try {
			await verifyAccount(token);
			setIsVerified(true);
		} catch (e) {
			if (isVerified !== true) {
				setErrorMessage(e.message);
				setIsVerified(false);
			}
		}
	};

	return {
		trigger: (verifyToken) => {
			onOpen();
			sendVerify(verifyToken);
		},
		close: redirectGomojo,
		modal: () => (
			<Modal isCentered closeOnOverlayClick={false} closeOnEsc={false} isOpen={isOpen}>
				<ModalOverlay backgroundColor="rgba(13,71,161,0.9)" zIndex={1400} />
				<ModalContent borderRadius="30px" my={0} py="1rem" px="2rem">
					{isVerified === null && (
						<Center>
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
						</Center>
					)}
					{isVerified === true && <SuccessContent onClose={redirectGomojo} />}
					{isVerified === false && <ErrorContent onClose={redirectGomojo} message={errorMessage} />}
				</ModalContent>
			</Modal>
		),
	};
};

export default useVerifyModal;
