import React, { useEffect } from 'react';

import useVerifyModal from 'components/Modals/MailVerify';
import useModalResetPassword from 'components/Modals/ResetPassword';

const Home = () => {
	const { trigger: triggerVerify, modal: VerifyEmailModal } = useVerifyModal();
	const { trigger: triggerRecover, modal: ResetPasswordModal } = useModalResetPassword();

	useEffect(() => {
		const pathName = window.location.pathname;
		const token = pathName.split('/').pop();
		if (pathName.includes('/reset')) triggerRecover(token);
		if (pathName.includes('/verify')) triggerVerify(token);
	}, []);

	return (
		<>
			<ResetPasswordModal />
			<VerifyEmailModal />
		</>
	);
};

export default Home;
