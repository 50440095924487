export const hasProperty = (obj, prop) => !!Object.getOwnPropertyDescriptor(obj, prop);

export const handler = (err) => {
	let error = err;

	if (err.response !== undefined) {
		if (hasProperty(err.response.data, 'message')) error = err.response.data;
	} else if (!hasProperty(err, 'message')) error = err.toJSON();
	return new Error(error.message);
};

const acceptError = ['email', 'username', 'password', 'confirmPassword', 'sponsorField'];
export const authHandler = (err) => {
	let error = err;
	if (err.response !== undefined) {
		if (hasProperty(err.response.data, 'message')) {
			const indexKey = err.response.data.message.indexOf(':');
			if (acceptError.includes(err.response.data.message.substr(0, indexKey))) {
				error.message = `{"${error.response.data.message.substr(0, indexKey)}": "${error.response.data.message.substr(
					indexKey + 1,
				)}"}`;
			} else error = err.response.data;
		} else if (acceptError.includes(Object.keys(err.response.data.error)[0]))
			error.message = JSON.stringify(err.response.data.error);
	} else if (!hasProperty(err, 'message')) {
		error = err.toJSON();
	}
	switch (error.message) {
		case 'Network Error':
			error.message = 'Une erreur est survenue veuillez réessayer ultérieurement';
			break;
		case 'Invalid email or password':
			error.message = 'Adresse e-mail ou mot de passe invalide';
			break;
		case 'Bad Request':
			error.message = 'Une erreur est survenue veuillez réessayer ultérieurement';
			break;
		default:
	}
	if (error.message.includes('TypeError') || error.message === '')
		error.message = 'Une erreur est survenue veuillez réessayer ultérieurement';
	return new Error(error.message);
};
