import axiosAPI from 'utils/axiosApi';
import { handler } from './handler';

export const verifyAccount = async (token) => {
	try {
		const { data } = await axiosAPI.get(`/auth/verify/${token}`);
		return data;
	} catch (e) {
		throw handler(e);
	}
};

export const recoverPassword = async (data, token) => {
	const res = await axiosAPI.post(`/auth/reset/${token}`, data);
	return res.data;
};
